import { goBackNTimes, HubbleRoute, pushPage } from "~/shared_states/modal";
import { CollectPhoneNumber } from "./collect_phone";
import { CollectGiftOtpProps, CollectOtp } from "./collect_otp";
import { clientRepo } from "~/server/apis/client_repo";
import { DeliveryMethod } from "~/server/types/gift";
import { Header } from "~/types";

export function verifyGiftV3<T>(props: {
  onVerificationSuccess: (response: T) => void;
  giftId: string;
  deviceVerificationToken: string;
  deliveryMethod: DeliveryMethod;
  defaultEventProps?: Record<string, any>;
}) {
  const deviceVerificationToken = props.deviceVerificationToken;
  pushPage(
    () => (
      <CollectPhoneNumber
        submitPhoneNumber={async (number) => {
          return await clientRepo.sendGiftOtpV3({
            id: props.giftId,
            identifier: number,
            headers: {
              [Header.FpDeviceToken]: deviceVerificationToken,
              [Header.SessionId]: "",
            },
          });
        }}
        onValidPhoneNumberSubmitted={(phoneNumber, otpToken) => {
          collectOtp<T>({
            phoneNumber: phoneNumber,
            submitOtp: async (submitOtpProps) => {
              return (await clientRepo.verifyGiftOtpV3({
                id: props.giftId,
                otp: submitOtpProps.otp,
                otpToken: submitOtpProps.otpToken,
                headers: {
                  [Header.FpDeviceToken]: deviceVerificationToken,
                  [Header.SessionId]: "",
                },
              })) as T;
            },
            onVerificationSuccess: props.onVerificationSuccess,
            otpToken: otpToken,
            resendOtp: async (number) => {
              return await clientRepo.sendGiftOtpV3({
                id: props.giftId,
                identifier: number,
                headers: {
                  [Header.FpDeviceToken]: deviceVerificationToken,
                  [Header.SessionId]: "",
                },
              });
            },
          });
        }}
        subtitle={
          props.deliveryMethod === "WHATSAPP"
            ? "Card details will be sent to your Whatsapp"
            : "Please note that you can’t change this later"
        }
      />
    ),
    {
      isModal: true,
      disableCloseButton: true,
      preventClose: false,
      enterAnimation: false,
    },
    HubbleRoute.COLLECT_PHONE_NUMBER_GIFTING
  );
}

export function collectOtp<T>(props: CollectGiftOtpProps<T>) {
  pushPage(
    () => (
      <CollectOtp
        phoneNumber={props.phoneNumber}
        submitOtp={props.submitOtp}
        onVerificationSuccess={async (response) => {
          props.onVerificationSuccess(response);
        }}
        otpToken={props.otpToken}
        resendOtp={props.resendOtp}
        buildSubtitle={props.buildSubtitle}
      />
    ),
    {
      isModal: true,
      disableCloseButton: true,
      preventClose: false,
      enterAnimation: false,
    },
    HubbleRoute.COLLECT_OTP_GIFTING
  );
}

export type SubmitPhoneNumberProps = {
  otpToken: string;
};
